import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CelumPropertiesProvider } from '@celum/core';
import { TranslationHelper } from '@celum/ng2base';

import { TokenRequestDto } from './model/auth-token.model';
import { AuthService } from './services/auth.service';
import { ServiceAccessTokenProvider } from './services/service-access-token-provider.service';
import { getAuthToken } from './utils/auth-token-util';

export interface ServiceTokenInterceptorConfig<T> {
  /**
   * Returns the interceptor configuration. This is called before each request, so keep it as lightweight as possible
   */
  getInterceptorConfiguration(): T;
}

export interface AuthInterceptorConfig {
  /**
   * Configuration per url that should be intercepted
   */
  apiConfigurations: ApiConfiguration[];
  /**
   * Whether to ignore 403 errors during authentication (forcing the application to deal with it instead)
   */
  passThrough403Error?: boolean;
}

export interface ApiConfiguration {
  /**
   * Interceptor will attach the provided dto to http requests starting with this urls
   */
  apiUrls: string[];
  /**
   * Request for either a b2cToken or the specific dto that SACC requires to acquire the service access token
   */
  serviceTokenRequestDto: TokenRequestDto;
  /**
   * TenantId (a.k.a. organizationId) required for some backend calls. Will be attached as 'X-Tenant'-header
   */
  tenantIdHeader?: string;
}

export const SERVICE_TOKEN_INTERCEPTOR_CONFIG = new InjectionToken<ServiceTokenInterceptorConfig<AuthInterceptorConfig>>('Service Token Interceptor Config');

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly ERROR_KEY = 'errorKey';

  constructor(
    private authService: AuthService,
    protected router: Router,
    @Inject(DOCUMENT) private document: Document,
    private serviceAccessTokenProvider: ServiceAccessTokenProvider,
    private translation: TranslationHelper,
    @Inject(SERVICE_TOKEN_INTERCEPTOR_CONFIG) private interceptorConfig: ServiceTokenInterceptorConfig<AuthInterceptorConfig>
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.getB2CToken(req).pipe(
      switchMap(token => this.cloneHttpRequest(req, token)),
      switchMap(clone => next.handle(clone)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && error.headers.get(this.ERROR_KEY) === 'token-expired') {
          this.document.location.reload();
          return EMPTY;
        } else if (
          !this.interceptorConfig.getInterceptorConfiguration().passThrough403Error &&
          error.status === 403 &&
          req.url.startsWith(CelumPropertiesProvider.properties.authentication.saccUrl)
        ) {
          // If a request to fetch something from SACC leads to a 403, we don't have the permission/privilege, so redirect to the fallback page
          // Using document.location here, because for some reason the router doesn't navigate anywhere from here
          this.document.location.replace(CelumPropertiesProvider.properties.authentication.applicationFallbackPagesPerLanguage[this.translation.locale]);
          return EMPTY;
        }

        return throwError(() => error);
      })
    );
  }

  /**
   * Clone the http request and add tokens automatically
   * @param req - The request ot clone
   * @param b2CToken - B2C token for SACC calls
   * @private
   */
  private cloneHttpRequest(req: HttpRequest<any>, b2CToken?: string): Observable<HttpRequest<any>> {
    // Get the injected config and check if it maps to the current request
    const apiConfigurations = this.interceptorConfig?.getInterceptorConfiguration()?.apiConfigurations;
    const dto = apiConfigurations?.find(config => config.apiUrls.find(apiUrl => req.url.startsWith(apiUrl)));

    if (!b2CToken && !dto) {
      return of(req);
    }

    // Attach B2C token automatically to calls to SACC
    if (req.url.includes(CelumPropertiesProvider.properties.authentication.saccUrl) && b2CToken) {
      return of(req.clone({ setHeaders: { Authorization: `Bearer ${b2CToken}` } }));
    }

    // Calls to services get the according service access token attached
    if (dto) {
      return getAuthToken(req.url, this.interceptorConfig, this.serviceAccessTokenProvider).pipe(
        map(token => (token ? req.clone({ setHeaders: { Authorization: `Bearer ${token}`, 'X-Tenant': dto.tenantIdHeader } }) : req))
      );
    }

    return of(req);
  }

  private getB2CToken(req: HttpRequest<any>): Observable<string> {
    if (!req.url.startsWith('http')) {
      return of(null);
    }

    return this.authService.getAuthResult().pipe(map(result => result?.idToken ?? ''));
  }
}
