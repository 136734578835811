export type AuthToken = {
  token: string;
  expiresAt?: Date;
};

export type NamedAuthToken = AuthToken & { name: string };

export type B2CTokenRequestDto = { b2cTokenRequest: boolean };

export const B2C_SERVICE_TOKEN_REQUEST_DTO: B2CTokenRequestDto = { b2cTokenRequest: true };

export type ServiceTokenRequestDto = {
  clientId: string;
  orgId: string;
};

export type TokenRequestDto = ServiceTokenRequestDto | B2CTokenRequestDto;

export function isB2CTokenRequestDto(dto: TokenRequestDto): dto is B2CTokenRequestDto {
  return (dto as B2CTokenRequestDto)?.b2cTokenRequest === true;
}
